.home{
    display: flex;
    margin: 20px;
    flex-direction: column;
    align-items: center;
}

#text{
    background-color:dimgray;
    color: white;
    width: fit-content;
    padding: 10px;
    margin-top: 10px;
    border-radius: 10px;
    width: 25%;
}
