.nav{
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color:dimgray;
    background-image: url('color-border.jpg');
    background-repeat: repeat-x; 
    background-size: 100px;
    background-position: center;
    
}
#card-container{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: dimgray;
}
.card{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(241, 239, 233);
    height: 50px;
    width: 100px;  
    margin: 5px;
    
    border-radius: 10px;
}
.card:hover{
    background-color:floralwhite;
}
.link{
    text-decoration: none;
    border-radius: 10px;
}