.Blog {
  text-align: center;
}

#next{
  background-color: dimgray; 
  border-radius: 5px;
  border: none;
  color: white;
  margin: 15px;
  margin-top:40px;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
#next:hover{
  background-color: floralwhite;
  color: dimgray;
}

div.scrollmenu {
  background-color: rgb(253, 51, 51);
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu a {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

div.scrollmenu a:hover {
  background-color: #777;
}

