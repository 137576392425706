.main-skills{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
    
}
#text-items{
    background-color: white;
    width:50%;
    border-radius: 20px;
    border:1px dimgray solid;
}